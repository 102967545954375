import TextButtonIcon from "@@/components/Button/TextButtonIcon";
import { BackgroundColor } from "@@/containers/Cards/service-description";
import Box from "@@/elements/Box";
import { ArrowRight } from "@@/elements/Icons/icons";
import Picture from "@@/elements/Picture";
import Typography from "@@/elements/Typography";
import { LinkData } from "@@/models/linkdata";
import { PictureData } from "@@/models/pictureData";
import cn from "classnames";
import cardStyles from "../cardStyles.module.scss";
import { colorMapperCard } from "../Service-description";
import styles from "./index.module.scss";

export type SublevelCardProps = {
  header: string;
  image: PictureData;
  intro: string;
  link: LinkData;
  backgroundColor?: BackgroundColor;
};

export const SublevelCard = ({
  header,
  image,
  intro,
  link,
  backgroundColor = "beige",
}: SublevelCardProps) => {
  const cardClassNames = cn(cardStyles.card, styles.sublevel_card, {
    [cardStyles.card__link]: link && link.href,
  });

  return (
    <Box
      backgroundColor={colorMapperCard(backgroundColor)}
      className={cardClassNames}
      width="full"
      height="full"
    >
      <a className={styles.card_content} href={link.href}>
        <div>
          <Typography
            py={6}
            textcolor="dark"
            textAlign="center"
            asElement="h3"
            variant="heading5"
          >
            {header}
          </Typography>
          <Picture
            imageData={image}
            aspectRatio="2 / 1"
            sizes={[300, 340, 560, 670, 1120, 1200, 1600]}
          />
        </div>
        {intro && (
          <Box className={styles.intro_wrapper} pt={6} px={6}>
            <Typography textcolor="dark" variant="body">
              {intro}
            </Typography>
          </Box>
        )}
        <Box className={styles.button_wrapper} pr={6} py={6}>
          <TextButtonIcon dense label={link.text} link={link} direction="right">
            <ArrowRight />
          </TextButtonIcon>
        </Box>
      </a>
    </Box>
  );
};

export default SublevelCard;
