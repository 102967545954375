import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import {
  List, Map, Pin, Reset,
} from "@@/elements/Icons/icons";
import Typhography from "@@/elements/Typography";
import { useJsApiLoader } from "@react-google-maps/api";
import cn from "classnames";
import { useEffect, useState } from "react";
import ErrorAlert from "../Error/ErrorAlert";
import SkeletonBox from "../Loader/Skeleton";
import GoogleMap from "../Map";
import GoogleMapsConfig from "../Map/config";
import BranchList from "./BranchList";
import BranchCard, { Location } from "./BranchList/card";
import BranchSearchInput from "./SearchInput";
import styles from "./styles.module.scss";

type Props = {
  locations: Location[];
  searchLabel: string;
  searchPlaceholderLabel: string;
  findCloseLabel: string;
  showMapLabel: string;
  showListLabel: string;
  resetLabel: string;
  branchHitLabel: string;
  localityHitLabel: string;
  placeHitLabel: string;
  noHitsLabel: string;
  googleMapsAPIKey: string;
  googleMapsId: string;
  googleMapsAPICountryRestriction: string[];
  googleMapsAPILanguage: string;
};

const BranchSearch = ({
  locations,
  searchLabel,
  searchPlaceholderLabel,
  findCloseLabel,
  showMapLabel,
  showListLabel,
  resetLabel,
  branchHitLabel,
  localityHitLabel,
  placeHitLabel,
  noHitsLabel,
  googleMapsAPIKey,
  googleMapsId,
  googleMapsAPICountryRestriction,
  googleMapsAPILanguage,
} : Props) => {
  const googleMapsConfig = new GoogleMapsConfig();

  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googleMapsAPIKey,
    libraries: googleMapsConfig.getLibraries(),
    language: googleMapsAPILanguage,
  });

  const [searchInput, setSearchInput] = useState("");
  const [error, setError] = useState<google.maps.places.PlacesServiceStatus>(null);
  const [mapView, setMapView] = useState(false);
  const [navigatiorLocation, setNavigatiorLocation] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [visibleLocations, setVisibleLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState(null);
  const [markedLocation, setMarkedLocation] = useState(null);
  const [hoveredLocation, setHoveredLocation] = useState(null);
  const [hasBoundsChanged, setHasBoundsChanged] = useState(false);

  const [selectedBranches, setSelectedBranches] = useState(locations);
  const [selectedLocality, setSelectedLocality] = useState(null);

  useEffect(() => {
    if (selectedLocality) {
      const filteredBranches = locations
        .filter((location) => location.locality === selectedLocality)
        .map((location) => location.id);
      setSelectedLocations(filteredBranches);
    } else {
      setSelectedLocations(null);
    }
  }, [selectedLocality]);

  const getDistances = (toLocations) => {
    if (!navigatiorLocation) return toLocations;
    return toLocations.map((location) => {
      const locationLatLng = new google.maps.LatLng(location.position.lat, location.position.lng);
      const distance = google.maps.geometry.spherical.computeDistanceBetween(navigatiorLocation, locationLatLng);
      return {
        ...location,
        distance,
      };
    }).sort((a, b) => a.distance - b.distance);
  };

  useEffect(() => {
    if (!markedLocation) {
      setSelectedBranches(visibleLocations);
    } else {
      setSelectedBranches([markedLocation]);
    }
  }, [visibleLocations, markedLocation, navigatiorLocation]);

  const reset = () => {
    setSelectedLocality(null);
    setSelectedLocations(null);
    setHasBoundsChanged(false);
    setMarkedLocation(null);
    setCurrentLocation(null);
    setError(null);
    setSearchInput("");
  };

  useEffect(() => {
    setError(null);
  }, [selectedLocality, selectedLocations, hasBoundsChanged, markedLocation]);

  const resetDisabled = !selectedLocality && !hasBoundsChanged && !markedLocation && !error;

  const getCurrentPosition = async () => {
    if (navigator) {
      navigator.geolocation.getCurrentPosition(({ coords }) => {
        const { latitude, longitude } = coords;
        const position = new google.maps.LatLng(latitude, longitude);
        setNavigatiorLocation(position);
      }, (e) => {
        console.warn("Cant find location, reason: ", e);
      }, {
        enableHighAccuracy: false,
        timeout: 5000,
        maximumAge: Infinity,
      });
    }
  };

  const onFindNearMe = () => {
    setCurrentLocation(new google.maps.LatLng(navigatiorLocation));
    setMarkedLocation(null);
  };

  useEffect(() => {
    if (isLoaded) {
      getCurrentPosition();
    }
  }, [isLoaded]);

  if (!googleMapsAPIKey || !googleMapsAPIKey.length) return <p>NO API KEY</p>;

  if (!isLoaded) {
    return (
      <Container>
        <Box className={styles.results_wrapper}>
          <SkeletonBox
            width="full"
            height="full" />
        </Box>
      </Container>
    );
  }

  if (loadError || (error && error !== google.maps.places.PlacesServiceStatus.ZERO_RESULTS)) return <ErrorAlert message={`Error code: ${error || "API_LOAD_ERROR"}`} />;

  return (
    <Container pageGutterX={[0, 5, 10]}>
      <Container
        pageGutter={0}
        pageGutterX={[5, 0, 0]}>
        <Box mb={4}>
          <BranchSearchInput
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            locations={locations}
            setSelectedLocations={setSelectedLocations}
            setSelectedLocality={setSelectedLocality}
            setCurrentLocation={setCurrentLocation}
            setMarkedLocation={setMarkedLocation}
            setError={setError}
            label={searchLabel}
            placeholder={searchPlaceholderLabel}
            branchHitLabel={branchHitLabel}
            localityHitLabel={localityHitLabel}
            placeHitLabel={placeHitLabel}
            googleMapsAPICountryRestriction={googleMapsAPICountryRestriction}
            googleMapsAPILanguage={googleMapsAPILanguage}
            isLoaded={isLoaded}
            error={!!loadError}
          />
        </Box>
        <Box
          flexDirection="row"
          justifyContent="space-between"
          pb={[5, 5, 6]}
          gap={2}>
          <button
            className={cn(styles.button__get_location, styles.button)}
            type="button"
            disabled={!navigatiorLocation}
            onClick={onFindNearMe}
          >
            <Pin />
            <Typhography
              variant="body"
              asElement="div">{findCloseLabel}</Typhography>
          </button>
          <button
            className={cn(styles.button__toggle_view, styles.button)}
            type="button"
            onClick={() => setMapView(!mapView)}
          >
            {mapView ? <List /> : <Map />}
            <Typhography
              variant="body"
              asElement="div">{mapView ? showListLabel : showMapLabel }</Typhography>
          </button>
        </Box>
        <button
          className={cn(styles.button__reset, styles.button)}
          type="button"
          disabled={resetDisabled}
          onClick={reset}
        >
          <Reset />
          <Typhography variant="link">{resetLabel}</Typhography>
        </button>
      </Container>
      <Container
        pageGutter={0}
        className={styles.results_wrapper}
      >
        <>
          <Box
            px={[5, 0, 0]}
            className={cn(styles.branchlist_wrapper, { [styles.branchlist_wrapper__is_hidden]: mapView })}>
            <BranchList
              selectedLocations={selectedLocations}
              locations={getDistances(selectedBranches)}
              setLocality={setSelectedLocality}
              setHoveredLocation={setHoveredLocation}
              noHitsLabel={noHitsLabel}
              error={error}
            />
          </Box>
          <Box className={cn(styles.map_wrapper, { [styles.map_wrapper__is_hidden]: !mapView })}>
            <GoogleMap
              locations={locations}
              hasBoundsChanged={hasBoundsChanged}
              setHasBoundsChanged={setHasBoundsChanged}
              setVisibleLocations={setVisibleLocations}
              selectedLocations={selectedLocations}
              currentLocation={currentLocation}
              markedLocation={markedLocation}
              setMarkedLocation={setMarkedLocation}
              hoveredLocation={hoveredLocation}
              googleMapsId={googleMapsId}
              isLoaded={isLoaded}
              error={!!loadError}
            />
          </Box>
          {(markedLocation && mapView) && (
            <Box
              className={styles.marked_location}
              width="full">
              <BranchCard
                {...getDistances([markedLocation])[0]}
                variant="single"
                onReset={() => setMarkedLocation(null)}
              />
            </Box>
          )}
        </>
      </Container>
    </Container>
  );
};

export default BranchSearch;
