import Container from "@@/elements/Container";
import useScript from "@@/hooks/use-script";
import InnerHTML from "dangerously-set-html-content";
import { useEffect, useState } from "react";
import CookieConsentComponent from "./cookieConsent";

type FormProps = {
  backgroundColor: "beige" | "white";
  header: string;
  messageText: string;
  linkLabel: string;
  form: any;
  hasConsent: boolean;
};

type CookieConsentObject = {
  stamp: string;
  necessary: boolean;
  preferences: boolean;
  statistics: boolean;
  marketing: boolean;
  method: string;
};

const EmbeddedForm = ({
  backgroundColor,
  header,
  messageText,
  linkLabel,
  form,
  hasConsent,
}: FormProps) => {
  const [marketingCookie, setMarketingCookie] = useState<boolean>(hasConsent);
  const [status, setStatus] = useState<unknown>();
  const scriptStatus = useScript(getScriptSrc(form), {
    removeOnUnmount: false,
  });
  const cookieBotAcceptHandler = (e) => {
    const cookie = (e.target as any)?.CookieConsent
      ?.consent as CookieConsentObject;
    if (cookie === null || cookie === undefined || !cookie?.marketing) {
      setMarketingCookie(false);
      return;
    }
    setMarketingCookie(true);
  };

  function getScriptSrc(variable) {
    const scriptTagRegex =
      /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*src=["'](.*?)["']/gi;
    const match = scriptTagRegex.exec(variable);

    if (match && match.length >= 2) {
      return match[1];
    }
    return null;
  }

  function hasScriptTag(variable) {
    const scriptTagRegex =
      /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;
    return scriptTagRegex.test(variable);
  }

  useEffect(() => {
    getStatus();
  }, [scriptStatus]);

  useEffect(() => {
    addEventListener("CookiebotOnAccept", cookieBotAcceptHandler);
    addEventListener("CookiebotOnDecline", cookieBotAcceptHandler);
  }, []);

  const getStatus = () => {
    if (hasScriptTag(form)) {
      setStatus(scriptStatus);
    } else {
      setStatus("ready");
    }
  };
  const getbackgroundColor = () => {
    if (backgroundColor === "beige") {
      return "ComplementaryBeigeTint03";
    } else return "White";
  };

  return (
    <>
      <Container
        className={`embedded-form-container ${backgroundColor}`}
        backgroundColor={getbackgroundColor()}
        pageGutter={0}
      >
        {marketingCookie && status === "ready" && <InnerHTML html={form} />}
        {!marketingCookie && (
          <CookieConsentComponent
            header={header}
            body={messageText}
            buttonLabel={linkLabel}
            backgroundColor={
              backgroundColor === "white" ? "PrimaryBlueTint03" : "White"
            }
          />
        )}
      </Container>
    </>
  );
};

export default EmbeddedForm;
