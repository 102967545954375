import ErrorAlert from "@@/components/Error/ErrorAlert";
import NewsCards from "@@/components/ListingPage/News/Cards";
import Pagination from "@@/components/Pagination";
import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import useUpdateEffect from "@@/hooks/useUpdateEffect";
import {
  useEffect, useState,
} from "react";
import { scroller } from "react-scroll";
import ListingNewsFilter from "../Filter/filter";
import useFetch, { ErrorCodes } from "../useFetchNewsData";

export enum VARIANTS {
  NEWS = "news",
  ARTICLES = "articles",
}

type SearchRequest = {
  language: string;
  parentpageid: number;
  page: number;
  categories: number[];
  years: number[];
};

type ListingNewsProps = {
  filterLabel?: string;
  resetLabel?: string;
  totalMatchesLabel: string;
  yearLabel?: string;
  resetYearLabel?: string;
  noResultMessage: string;
  errorMessage: string;
  errorActionMessage: string;
  variant?: VARIANTS;
  searchRequest?: SearchRequest;
};

const ListingNewsContainer = ({
  filterLabel,
  resetLabel,
  totalMatchesLabel,
  yearLabel,
  resetYearLabel,
  noResultMessage,
  errorMessage,
  errorActionMessage,
  variant = VARIANTS.NEWS,
  searchRequest,
}: ListingNewsProps) => {
  const PARAMETERS = {
    CATEGORIES: "c",
    PAGE: "p",
    YEAR: "y",
    LANGUAGE: "l",
  };

  const [selectedYears, setSelectedYears] = useState(searchRequest.years ?? []);
  const [selectedCategories, setSelectedCategories] = useState(searchRequest.categories ?? []);
  const [selectedPage, setSelectedPage] = useState(searchRequest.page ?? 1);

  const [hasTabNav, setHasTabNav] = useState(false);

  const {
    fetchData, error, loading,
  } = useFetch(
    selectedPage,
    selectedCategories,
    selectedYears,
    variant,
    searchRequest,
  );

  const showLoader = loading && !fetchData;

  const showPagination = fetchData && !!fetchData.items.length && fetchData.totalPage > 1;

  useEffect(() => {
    setHasTabNav(!!document.getElementById("stena-tab-navigation"));
  }, []);

  useEffect(() => {
    const { search, hash } = window.location;
    const params = new URLSearchParams(search);

    params.set(PARAMETERS.PAGE, selectedPage.toString());
    if (selectedYears?.length > 0) {
      params.set(PARAMETERS.YEAR, selectedYears.toString());
    } else {
      params.delete(PARAMETERS.YEAR);
    }
    if (selectedCategories?.length > 0) {
      params.set(PARAMETERS.CATEGORIES, selectedCategories.toString());
    } else {
      params.delete(PARAMETERS.CATEGORIES);
    }

    window.history.replaceState(null, null, `?${params.toString()}${hash}`);
  }, [selectedYears, selectedCategories, selectedPage]);

  useUpdateEffect(() => {
    setSelectedPage(1);
  }, [selectedYears, selectedCategories]);

  useUpdateEffect(() => {
    if (hasTabNav) scroller.scrollTo("stena-tab-navigation", { duration: 200, smooth: true, offset: -100 });
    else scroller.scrollTo("listing-page-scroll", { duration: 200, smooth: true, offset: -100 });
  }, [selectedPage]);

  const reset = () => {
    setSelectedCategories([]);
    setSelectedYears([]);
  };

  if (error) {
    const errorText = error === ErrorCodes.NO_RESULT ? noResultMessage : errorMessage;
    return (
      <ErrorAlert
        label={`${totalMatchesLabel}: 0`}
        message={errorText}
        actionText={errorActionMessage}
        action={reset}
      />
    );
  }

  return (
    <Container
      pageGutterX={0}
      customStyle={{ paddingTop: hasTabNav && "0px" }}
      size="full"
      id="listing-page-scroll">
      <ListingNewsFilter
        filterLabel={filterLabel}
        clearFilterLabel={resetLabel}
        categories={fetchData?.categoryFacets}
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
        years={fetchData?.yearFacets}
        selectedYears={selectedYears}
        setSelectedYears={setSelectedYears}
        yearLabel={yearLabel}
        resetYearLabel={resetYearLabel}
        loading={showLoader}
        reset={reset}
      />
      <Box
        backgroundColor="ComplementaryBeigeTint04"
        width="full"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        pt={[8, 20, 25]}
        pb={[10, 13, 16]}
      >
        <Container pageGutterY={0}>
          <NewsCards
            items={fetchData?.items}
            label={`${totalMatchesLabel}: ${fetchData?.totalMatching}`}
            loading={loading}
            noResultMessage={noResultMessage}
            resetMessage={errorActionMessage}
            reset={reset}
          />
          {showPagination && (
            <Box mt={[7, 10, 10]}>
              <Pagination
                selectedValue={selectedPage}
                totalPages={fetchData?.totalPage}
                setSelectedValue={setSelectedPage}
                loading={showLoader} />
            </Box>
          )}
        </Container>
      </Box>
    </Container>
  );
};

export default ListingNewsContainer;
