import Box from "@@/elements/Box";
import { ArrowLeft, ArrowRight } from "@@/elements/Icons/icons";
import Typhography from "@@/elements/Typography";
import cn from "classnames";
import { useEffect, useState } from "react";
import SkeletonBox from "../Loader/Skeleton";
import styles from "./styles.module.scss";
import { DOTS, usePagination } from "./usePagination";

type PaginationProps = {
  selectedValue: number;
  totalPages: number;
  setSelectedValue: Function;
  loading?: boolean;
  dark?: boolean;
};

const Pagination = ({
  selectedValue, totalPages, setSelectedValue, loading, dark,
}: PaginationProps) => {
  const paginationRange = usePagination({
    currentPage: selectedValue,
    totalCount: totalPages,
  });

  const [path, setPath] = useState("");

  useEffect(() => {
    const { pathname } = window.location;
    setPath(pathname);
  }, []);

  const showNextButton = selectedValue < totalPages;
  const incrementPage = (e) => {
    e.preventDefault();
    if (showNextButton) {
      setSelectedValue(selectedValue + 1);
    }
  };

  const showPreviousButton = selectedValue > 1;
  const decrementPage = (e) => {
    e.preventDefault();
    if (showPreviousButton) {
      setSelectedValue(selectedValue - 1);
    }
  };

  const onClickPage = (e, page) => {
    e.preventDefault();
    setSelectedValue(page);
  };

  const leftButtonClassName = cn(
    styles.button_wrapper,
    { [styles.button_wrapper__hidden]: selectedValue === 1 },
  );

  const rightButtonClassName = cn(
    styles.button_wrapper,
    { [styles.button_wrapper__hidden]: selectedValue === totalPages },
  );

  const renderPaginationPage = (page: number, selected: boolean) => (
    <a
      className={cn(styles.page, { [styles.page__current]: selected, [styles.page__current__dark]: selected && dark })}
      onClick={(e) => onClickPage(e, page)}
      href={`${path}?p=${page}`}
      role="button"
      tabIndex={0}
    >
      <Typhography variant="information">
        {page}
      </Typhography>
    </a>
  );

  const renderPages = () => (
    <>
      <Box className={styles.pagination__large} flexDirection="row">
        {paginationRange.map((page, i) => (
          <Box key={i} alignItems="center">
            {page > 0 && renderPaginationPage(page, page === selectedValue)}
            {page === DOTS && (
            <>...</>
            )}
            {page !== totalPages && <Box mx={2} className={styles.divider} />}
          </Box>
        ))}
      </Box>
      <Box className={styles.pagination__small} flexDirection="row">
        <>
          {renderPaginationPage(selectedValue, true)}
          <Box mx={2} className={styles.divider} />
          {renderPaginationPage(totalPages, false)}
        </>
      </Box>
    </>
  );

  if (loading) {
    return (
      <Box justifyContent="space-between">
        <SkeletonBox width={10} height={10} />
        <SkeletonBox width={[15, 60, 70]} height={10} />
        <SkeletonBox width={10} height={10} />
      </Box>
    );
  }

  return (
    <Box alignItems="center" justifyContent="space-between" width="full">
      <Box className={leftButtonClassName} justifyContent="flex-start">
        {showPreviousButton && (
        <a onClick={(e) => decrementPage(e)} href={`${path}?p=${selectedValue - 1}`} title="Previous">
          <ArrowLeft />
        </a>
        )}
      </Box>
      {renderPages()}
      <Box className={rightButtonClassName} justifyContent="flex-end">

        {showNextButton && (
        <a onClick={(e) => incrementPage(e)} href={`${path}?p=${selectedValue + 1}`} title="Next">
          <ArrowRight />
        </a>
        )}
      </Box>
    </Box>
  );
};

export default Pagination;
