import Box from "@@/elements/Box";
import { ChevronRight } from "@@/elements/Icons/icons";
import Typography from "@@/elements/Typography";
import {
  ScreenSize,
  useIsBreakpointOrBelow,
} from "@@/hooks/device/use-breakpoint";
import { LinkData } from "@@/models/linkdata";
import styles from "./index.module.scss";

type BreadcrumbProps = {
  breadcrumbItems: LinkData[];
};

const Breadcrumbs = ({ breadcrumbItems }: BreadcrumbProps) => {
  const maxLength = 35;
  const isSmallAndBelow = useIsBreakpointOrBelow(ScreenSize.SMALL);
  const textEllipse = (text, maxLength) => {
    if (text.length > maxLength) {
      text = `${text.substring(0, maxLength)}...`;
    }
    return text;
  };
  return (
    <Box component="nav" aria-label="breadcrumbs">
      <ol className={styles.breadcrumb_list}>
        {breadcrumbItems &&
          breadcrumbItems.map((item, index, array) => {
            const isSmallScreen = () => {
              if (isSmallAndBelow && array.length - 2 === index) {
                return true;
              }
              return false;
            };
            const isLast = array.length - 1 === index;
            return (

              <li className={styles.breadcrumb_item} key={index}>
                {!isLast ? (
                  <a className={styles.breadcrumb_link} target={item.target} title={item.title} href={item.href}>
                    <Typography asElement="p" variant="link">
                      {item.text}
                    </Typography>
                  </a>
                ) : (
                  <div className={styles.breadcrumb_current} aria-current="location">
                    <Typography asElement="p" variant="label">
                      {textEllipse(item.text, maxLength)}
                    </Typography>
                  </div>
                )}
                {!(isLast || isSmallScreen()) && <ChevronRight className={styles.divider} /> }
              </li>

            );
          })}
      </ol>
    </Box>
  );
};

export default Breadcrumbs;
