import RichText from "@@/components/RichText";
import Box from "@@/elements/Box";
import { ChevronUp } from "@@/elements/Icons/icons";
import Typography from "@@/elements/Typography";
import OptimizelyBlock, {
  OptimizelyBlockProps,
} from "@@/integrations/optimizly/Block";
import EpiProperty from "@@/integrations/optimizly/Property";
import cn from "classnames";
import { useEffect, useRef, useState } from "react";
import styles from "./faqitem.module.scss";

export type FaqItemProperties = {
  header: string;
  body: string;
};

export type FaqOwnProps = {
  active: boolean;
  className?: string;
};

export type FaqProps = FaqOwnProps & FaqItemProperties;

const FaqItem = ({
  header, body, active = false, className,
}: FaqProps) => {
  const contentRef = useRef<HTMLInputElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  let contentHeight: number = 0;

  const close = () => {
    setIsOpen(false);
  };

  const open = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    if (active && active === true) {
      open();
    } else {
      close();
    }
  }, []);

  useEffect(() => {
    contentHeight = contentRef.current.scrollHeight;
    if (isOpen === false) {
      contentRef.current.style.maxHeight = `${contentHeight}px`;
      contentRef.current.style.maxHeight = "0px";
    } else {
      contentRef.current.style.maxHeight = `${0}px`;
      contentRef.current.style.maxHeight = `${contentHeight}px`;
    }
  }, [isOpen]);

  const handleClick = (e) => {
    contentHeight = contentRef.current.scrollHeight;
    if (isOpen) {
      close();
    } else {
      open();
    }
    e.preventDefault();
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleClick(e);
    }
  };

  return (
    <Box
      className={cn(className, styles.faqItem, {
        [styles["is-active"]]: isOpen,
      })}
      component="section"
      container
      flexDirection="column"
      py={16}
      mb={2}
    >
      <div
        aria-label={isOpen ? "close" : "open"}
        className={styles.faqItem_actionbutton}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex={0}
      >
        <ChevronUp />
      </div>
      <Box className="{styles.faqItem_container}" px={[14, 27, 27]}>
        <EpiProperty<FaqItemProperties> name="header">
          <Box className={styles.faqItem__header} onClick={handleClick}>
            <Typography variant="heading4" asElement="h3">
              {header}
            </Typography>
          </Box>
        </EpiProperty>
        <div className={styles.faqItem__content} ref={contentRef}>
          <EpiProperty<FaqItemProperties> name="body">
            <Box pt={4}>
              <RichText body={body} />
            </Box>
          </EpiProperty>
        </div>
      </Box>
    </Box>
  );
};

export type ProvidedFaqProps = OptimizelyBlockProps<FaqItemProperties> &
FaqProps;

const ProvidedFaq = ({
  blockId,
  inEditMode,
  properties,
  ...rest
}: ProvidedFaqProps) => (
  <OptimizelyBlock
    blockId={blockId}
    inEditMode={inEditMode}
    properties={properties}
  >
    {(optimizlyProperties) => (
      <FaqItem
        {...rest}
        {...optimizlyProperties}
        {...(inEditMode
          ? {
            active: true,
          }
          : {})}
      />
    )}
  </OptimizelyBlock>
);

export default ProvidedFaq;
