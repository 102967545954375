import SnwButton from "@@/components/Button/SnwButton";
import { SnwButtonType } from "@@/components/Button/buttonProps";
import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import { ChevronDown, Pause, Play } from "@@/elements/Icons/icons";
import Typhography from "@@/elements/Typography";
import OptimizelyBlock, {
  OptimizelyBlockProps,
} from "@@/integrations/optimizly/Block";
import EpiProperty from "@@/integrations/optimizly/Property";
import { LinkData } from "@@/models/linkdata";
import cn from "classnames";
import { useRef, useState } from "react";
import styles from "./styles.module.scss";

type EditableProps = {
  header: string;
  video: string;
};

type OwnProps = {
  fallbackImage?: string;
  logoUrl: string;
  logoAltText: string;
  logoLink: LinkData;
  logoSize: "large" | "small";
  button?: SnwButtonType;
  campaign: boolean;
  hasMenu: boolean;
  inEditMode?: boolean;
};

type Props = EditableProps & OwnProps;

const HeroEventVideo = ({
  header,
  video,
  fallbackImage,
  logoUrl,
  logoAltText,
  logoLink,
  logoSize = "large",
  button,
  campaign = true,
  hasMenu = false,
  inEditMode,
}: Props) => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const [showPlay, setShowPlay] = useState(false);

  const togglePlay = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
        setIsPlaying(true);
      } else {
        videoRef.current.pause();
        setIsPlaying(false);
      }
    }
  };

  const onKeyDown = (e) => {
    if (e.code === "Space") {
      togglePlay();
    }
  };

  const scrollDown = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (videoRef.current) {
      const bottomPos = videoRef.current.getBoundingClientRect().bottom;
      window.scrollBy(0, bottomPos);
    }
  };

  return (
    <Container
      backgroundColor="ComplementaryBeigeTint03"
      size="full"
      pageGutter={0}
    >
      <EpiProperty<EditableProps> name="video">
        <div
          className={cn(
            styles.hero_wrapper,
            "hero-video",
            { [styles.preview]: inEditMode },
            {
              [styles.hero_wrapper__hasmenu]: hasMenu,
            },
          )}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => onKeyDown(e)}
          onClick={() => togglePlay()}
          onMouseEnter={() => setShowPlay(true)}
          onMouseLeave={() => setShowPlay(false)}
        >
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <video
            ref={videoRef}
            className={styles.video}
            poster={fallbackImage}
            muted
            loop
            autoPlay
            playsInline
          >
            <source src={video} />
          </video>

          <Container
            className={styles.content}
            component="div">
            <EpiProperty<EditableProps> name="header">
              <Typhography
                textAlign="center"
                variant="video-hero"
                color="White"
                asElement="h1"
              >
                {header}
              </Typhography>
            </EpiProperty>
            {button && (
              <Box
                mt={10}
                onClick={(e) => e.stopPropagation()}>
                <SnwButton
                  variant="tertiary"
                  color={button.color}
                  link={button.link}
                />
              </Box>
            )}
          </Container>
          {!hasMenu && (
            <a
              className={cn(styles.logo, {
                [styles.logo__small]: logoSize === "small",
              })}
              href={logoLink.href}
              target={logoLink.target}
              title={logoLink.title}
            >
              <img
                src={logoUrl}
                alt={logoAltText} />
            </a>
          )}
          <button
            onClick={scrollDown}
            className={cn(styles.scroll_icon, {
              [styles.scroll_icon__campaign]: campaign,
            })}
          >
            <ChevronDown />
          </button>
          <div
            className={cn(styles.pause_play_icon, {
              [styles.pause_play_icon__campaign]: campaign,
            })}
          >
            {!isPlaying && <Play />}
          </div>
          <div
            className={cn(styles.pause_play_icon, {
              [styles.pause_play_icon__campaign]: campaign,
              [styles.pause_play_icon__pause]: true,
            })}
          >
            {isPlaying && showPlay && <Pause />}
          </div>
        </div>
      </EpiProperty>
    </Container>
  );
};

export type ProvidedHeroEventProps = OptimizelyBlockProps<EditableProps> &
  Props;

const ProvidedHeroEventVideo = ({
  blockId,
  inEditMode,
  properties,
  ...rest
}: ProvidedHeroEventProps) => (
  <OptimizelyBlock
    blockId={blockId}
    inEditMode={inEditMode}
    properties={properties}
  >
    {(optimizlyProperties) => (
      <HeroEventVideo
        inEditMode={inEditMode}
        {...rest}
        {...optimizlyProperties}
      />
    )}
  </OptimizelyBlock>
);

export default ProvidedHeroEventVideo;
