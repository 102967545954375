import SnwButtonContainer from "@@/components/Button/SnwButtonContainer";
import { SnwButtonType } from "@@/components/Button/buttonProps";
import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import Picture from "@@/elements/Picture";
import Typhography from "@@/elements/Typography";
import OptimizelyBlock, {
  OptimizelyBlockProps,
} from "@@/integrations/optimizly/Block";
import EpiProperty from "@@/integrations/optimizly/Property";
import { LinkData } from "@@/models/linkdata";
import { PictureData } from "@@/models/pictureData";
import cn from "classnames";
import styles from "./styles.module.scss";

type Alignment = "left" | "center" | "right";

type EditableProps = {
  label: string;
  header: string;
  intro: string;
  image: PictureData;
};

export type OwnProps = {
  alignment: Alignment;
  logoUrlLight: string;
  logoUrlDark: string;
  logoAltText: string;
  logoLink: LinkData;
  logoSize: "large" | "small";
  campaign: boolean;
  hasMenu: boolean;
  buttons?: SnwButtonType[];
  inEditMode?: boolean;
};

export type Props = EditableProps & OwnProps;

const HeroEventImage = ({
  label,
  header,
  intro,
  image,
  alignment,
  logoUrlLight,
  logoUrlDark,
  logoAltText,
  logoLink,
  logoSize = "large",
  campaign = true,
  hasMenu = true,
  buttons,
  inEditMode,
}: Props) => {
  const textColor = image ? "light" : "dark";

  return (
    <Container
      backgroundColor={image ? "White" : "ComplementaryBeigeTint03"}
      size="full"
      pageGutter={0}
    >
      <EpiProperty<EditableProps> name="image">
        <div
          className={cn(
            styles.hero_wrapper,
            "hero-image",
            { [styles.preview]: inEditMode },
            {
              [styles.hero_wrapper__noimage]: !image,
            },
          )}
        >
          {image && (
            <Picture
              className={styles.image}
              imageData={image}
              aspectRatio={["1 / 2", "5 / 4", "21 / 9"]}
              sizes={[
                [200, 400, 600, 800, 1200, 1600, 3200, 3840],
                [200, 400, 600, 800, 1200, 1600, 3200, 3840],
                [200, 400, 600, 800, 1200, 1600, 3200, 3840],
              ]}
              lazySizes={false}
              loading="eager"
              sourcesSizes="(min-widht: 1920px) 1920px, 100vw"
            />
          )}
          <Container
            className={cn(styles.content, {
              [styles.content__small]:
                image && !hasMenu && logoSize === "small",
              [styles.content__large]:
                image && !hasMenu && logoSize === "large",
              [styles.content__noimage]: !image,
              [styles.content__center]: alignment === "center",
              [styles.content__end]: alignment === "right",
            })}
            pageGutterY={0}
            component="div"
          >
            <EpiProperty<EditableProps> name="label">
              {label && (
                <Typhography
                  variant="heading5"
                  textAlign={alignment}
                  textcolor={textColor}
                  asElement="div"
                >
                  {label}
                </Typhography>
              )}
            </EpiProperty>
            {label && (
              <hr
                className={cn(styles.line, {
                  [styles.line__dark]: !image,
                })}
              />
            )}
            <EpiProperty<EditableProps> name="header">
              <Typhography
                variant="hero"
                textAlign={alignment}
                textcolor={textColor}
                asElement="h1"
              >
                {header}
              </Typhography>
            </EpiProperty>
            {intro && (
              <EpiProperty<EditableProps> name="intro">
                <Typhography
                  mt={4}
                  variant="body"
                  textcolor={textColor}
                  textAlign={alignment}
                  asElement="p"
                >
                  {intro}
                </Typhography>
              </EpiProperty>
            )}
            {!!buttons?.length && (
              <Box
                mt={10}
                flexDirection={["column", "row", "row"]}
                gap={5}>
                <SnwButtonContainer buttons={buttons} />
              </Box>
            )}
          </Container>
          {!hasMenu && (
            <a
              className={cn(styles.logo, {
                [styles.logo__small]: logoSize === "small",
              })}
              href={logoLink.href}
              target={logoLink.target}
              title={logoLink.title}
            >
              <img
                src={image ? logoUrlLight : logoUrlDark}
                alt={logoAltText} />
            </a>
          )}
          {!campaign && (
            <div
              className={cn(styles.notch, {
                [styles.notch__image]: image,
              })}
            />
          )}
        </div>
      </EpiProperty>
    </Container>
  );
};

export type ProvidedHeroEventProps = OptimizelyBlockProps<EditableProps> &
  Props;

const ProvidedHeroEventImage = ({
  blockId,
  inEditMode,
  properties,
  ...rest
}: ProvidedHeroEventProps) => (
  <OptimizelyBlock
    blockId={blockId}
    inEditMode={inEditMode}
    properties={properties}
  >
    {(optimizlyProperties) => (
      <HeroEventImage
        inEditMode={inEditMode}
        {...rest}
        {...optimizlyProperties}
      />
    )}
  </OptimizelyBlock>
);

export default ProvidedHeroEventImage;
