/* eslint-disable quote-props */

// -----------------------------------------------------
// This file was generated, do not modify this directly.
// change the design token-file and run 'npm run generate:tokens'
// -------------------------------------------------------------------

export const allTokens = [
  "Black",
  "White",
  "PrimaryGreen",
  "PrimaryGrey",
  "PrimaryBlue",
  "PrimaryGreenTint01",
  "PrimaryGreenTint02",
  "PrimaryGreenTint03",
  "PrimaryGreyTint01",
  "PrimaryGreyTint02",
  "PrimaryGreyTint03",
  "PrimaryBlueTint01",
  "PrimaryBlueTint02",
  "PrimaryBlueTint03",
  "ComplementaryOrange",
  "ComplementaryBeige",
  "ComplementaryCharcoal",
  "ComplementaryBlue",
  "ComplementaryOrangeTint01",
  "ComplementaryOrangeTint02",
  "ComplementaryOrangeTint03",
  "ComplementaryOrangeTint04",
  "ComplementaryBeigeTint01",
  "ComplementaryBeigeTint02",
  "ComplementaryBeigeTint03",
  "ComplementaryBeigeTint04",
  "ComplementaryCharcoalTint01",
  "ComplementaryCharcoalTint02",
  "ComplementaryCharcoalTint03",
  "ComplementaryCharcoalTint04",
  "ComplementaryBlueTint01",
  "ComplementaryBlueTint02",
  "ComplementaryBlueTint03",
  "ComplementaryBlueTint04",
  "ShadesBlue01",
  "ShadesBlue02",
  "ShadesGreen01",
  "ShadesGreen02",
  "ShadesCharcoal01",
  "ShadesCharcoal02",
  "Info",
  "Success",
  "Warning",
  "Danger",
  "InfoTint",
  "SuccessTint",
  "WarningTint",
  "DangerTint",
  "Chart01",
  "Chart02",
  "Chart03",
] as const;

type tokenTuple = typeof allTokens;
export type Token = tokenTuple[number];

export const TokenAlias: Record<Token, string> = {
  "Black": "black",
  "White": "white",
  "PrimaryGreen": "primary-green",
  "PrimaryGrey": "primary-grey",
  "PrimaryBlue": "primary-blue",
  "PrimaryGreenTint01": "primary-green-tint01",
  "PrimaryGreenTint02": "primary-green-tint02",
  "PrimaryGreenTint03": "primary-green-tint03",
  "PrimaryGreyTint01": "primary-grey-tint01",
  "PrimaryGreyTint02": "primary-grey-tint02",
  "PrimaryGreyTint03": "primary-grey-tint03",
  "PrimaryBlueTint01": "primary-blue-tint01",
  "PrimaryBlueTint02": "primary-blue-tint02",
  "PrimaryBlueTint03": "primary-blue-tint03",
  "ComplementaryOrange": "complementary-orange",
  "ComplementaryBeige": "complementary-beige",
  "ComplementaryCharcoal": "complementary-charcoal",
  "ComplementaryBlue": "complementary-blue",
  "ComplementaryOrangeTint01": "complementary-orange-tint01",
  "ComplementaryOrangeTint02": "complementary-orange-tint02",
  "ComplementaryOrangeTint03": "complementary-orange-tint03",
  "ComplementaryOrangeTint04": "complementary-orange-tint04",
  "ComplementaryBeigeTint01": "complementary-beige-tint01",
  "ComplementaryBeigeTint02": "complementary-beige-tint02",
  "ComplementaryBeigeTint03": "complementary-beige-tint03",
  "ComplementaryBeigeTint04": "complementary-beige-tint04",
  "ComplementaryCharcoalTint01": "complementary-charcoal-tint01",
  "ComplementaryCharcoalTint02": "complementary-charcoal-tint02",
  "ComplementaryCharcoalTint03": "complementary-charcoal-tint03",
  "ComplementaryCharcoalTint04": "complementary-charcoal-tint04",
  "ComplementaryBlueTint01": "complementary-blue-tint01",
  "ComplementaryBlueTint02": "complementary-blue-tint02",
  "ComplementaryBlueTint03": "complementary-blue-tint03",
  "ComplementaryBlueTint04": "complementary-blue-tint04",
  "ShadesBlue01": "shades-blue01",
  "ShadesBlue02": "shades-blue02",
  "ShadesGreen01": "shades-green01",
  "ShadesGreen02": "shades-green02",
  "ShadesCharcoal01": "shades-charcoal01",
  "ShadesCharcoal02": "shades-charcoal02",
  "Info": "info",
  "Success": "success",
  "Warning": "warning",
  "Danger": "danger",
  "InfoTint": "info-tint",
  "SuccessTint": "success-tint",
  "WarningTint": "warning-tint",
  "DangerTint": "danger-tint",
  "Chart01": "chart01",
  "Chart02": "chart02",
  "Chart03": "chart03",
};

export const TokenValue: Record<Token, string> = {
  "Black": "#000000",
  "White": "#ffffff",
  "PrimaryGreen": "#657b6c",
  "PrimaryGrey": "#62636a",
  "PrimaryBlue": "#5a6c81",
  "PrimaryGreenTint01": "#93a498",
  "PrimaryGreenTint02": "#bfcac2",
  "PrimaryGreenTint03": "#e5ebe6",
  "PrimaryGreyTint01": "#8c8a8f",
  "PrimaryGreyTint02": "#c7c5c9",
  "PrimaryGreyTint03": "#efefef",
  "PrimaryBlueTint01": "#7c95ab",
  "PrimaryBlueTint02": "#b2bfcc",
  "PrimaryBlueTint03": "#e5eaf0",
  "ComplementaryOrange": "#a46351",
  "ComplementaryBeige": "#c5baac",
  "ComplementaryCharcoal": "#3f4347",
  "ComplementaryBlue": "#327ea5",
  "ComplementaryOrangeTint01": "#c18f81",
  "ComplementaryOrangeTint02": "#d1ada3",
  "ComplementaryOrangeTint03": "#e2cbc5",
  "ComplementaryOrangeTint04": "#ebdad6",
  "ComplementaryBeigeTint01": "#dbd3c8",
  "ComplementaryBeigeTint02": "#ebe7e0",
  "ComplementaryBeigeTint03": "#f2f1ed",
  "ComplementaryBeigeTint04": "#f7f5f3",
  "ComplementaryCharcoalTint01": "#565c61",
  "ComplementaryCharcoalTint02": "#6e757c",
  "ComplementaryCharcoalTint03": "#888f95",
  "ComplementaryCharcoalTint04": "#c9cccf",
  "ComplementaryBlueTint01": "#62abd0",
  "ComplementaryBlueTint02": "#89c0dc",
  "ComplementaryBlueTint03": "#b1d5e7",
  "ComplementaryBlueTint04": "#d8eaf3",
  "ShadesBlue01": "#478bae",
  "ShadesBlue02": "#286584",
  "ShadesGreen01": "#5f7466",
  "ShadesGreen02": "#55675a",
  "ShadesCharcoal01": "#3f4347",
  "ShadesCharcoal02": "#323639",
  "Info": "#327ea5",
  "Success": "#98b347",
  "Warning": "#e0b400",
  "Danger": "#de5c45",
  "InfoTint": "#e4f4fb",
  "SuccessTint": "#e8f3d3",
  "WarningTint": "#fef4c8",
  "DangerTint": "#fff0eb",
  "Chart01": "#c68d6c",
  "Chart02": "#98b347",
  "Chart03": "#327ea5",
};
