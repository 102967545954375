import TextButtonIcon from "@@/components/Button/TextButtonIcon";
import SearchCardsContainer from "@@/components/ListingPage/Search/Cards";
import HelpMessage from "@@/components/ListingPage/Search/HelpMessage";
import SearchInput from "@@/components/SearchInput/MainMenu";
import Box from "@@/elements/Box";
import { ArrowRight } from "@@/elements/Icons/icons";
import Typhography from "@@/elements/Typography";
import { ScreenSize, useIsBreakpointOrBelow } from "@@/hooks/device/use-breakpoint";
import { LinkData } from "@@/models/linkdata";
import cn from "classnames";
import { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import useFetchMainMenuSearchData from "./useFetchMainMenuSearch";

type Props = {
  open: boolean;
  setOpen: Function;
  searchData: {
    header: string;
    searchLink: LinkData;
    placeholder: string;
    totalMatchesLabel: string;
    noMatchesMessage: string;
    noResultMessage: string;
    noSearchMadeMessage: string;
    searchRequest: {
      language: string;
    }
  }
};

const MainMenuSearch = ({
  open,
  setOpen,
  searchData,
}: Props) => {
  const {
    header,
    searchLink,
    placeholder,
    totalMatchesLabel,
    noMatchesMessage,
    noResultMessage,
    noSearchMadeMessage,
    searchRequest,
  } = searchData;

  const ref = useRef(null);
  const searchClassNames = cn(styles.search, {
    [styles.search__open]: open,
  });

  const [visibleItems, setVisibleItems] = useState(5);
  const [input, setInput] = useState("");
  const { data, error, loading } = useFetchMainMenuSearchData(input, searchRequest.language);

  const showResults = (data && !error) || loading;
  const showHelpMessage = data && !data.items?.length && !error && !loading;

  const showMoreButton = !loading && !!data?.items?.length && searchLink;

  const url = `${searchLink?.href}?q=${input}`;

  const redirect = () => {
    window.location.href = url;
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const searchButton = document.getElementById("search_button");
      if (searchButton.contains(event.target)) return;
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false, event);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!open) setInput("");
  }, [open]);

  const isMobileDevice = useIsBreakpointOrBelow(ScreenSize.MEDIUM);
  useEffect(() => {
    const windowListener = () => {
      const { clientHeight } = document.documentElement;
      if (isMobileDevice) setVisibleItems(5);
      else if (clientHeight < 900) setVisibleItems(1);
      else if (clientHeight < 1000) setVisibleItems(2);
      else if (clientHeight < 1200) setVisibleItems(3);
      else if (clientHeight < 1400) setVisibleItems(4);
      else if (clientHeight < 1600) setVisibleItems(5);
      else setVisibleItems(6);
    };
    windowListener();
    window.addEventListener("resize", windowListener);
    return () => window.removeEventListener("resize", windowListener);
  }, [isMobileDevice]);

  return (
    <div
      className={styles.search_wrapper}
      ref={ref}
      id="search-wrapper">
      <Box className={searchClassNames}>
        <Box className={styles.search__content}>
          <Typhography
            className={styles.search__content__header}
            variant="heading1"
            asElement="div"
            textAlign="center"
            mt={8}
          >
            {header}
          </Typhography>
          {/* TODO: Replace with TextInput */}
          <SearchInput
            input={input}
            setInput={setInput}
            onClose={onClose}
            placeholder={placeholder}
            onSubmit={redirect}
          />
          {(showResults) && (
            <Box
              pt={[0, 10, 10]}
              pb={10}
              px={[6, 8, 8]}
              className={styles.search_cards_wrapper}>
              {showHelpMessage && (
                <HelpMessage
                  noMatchesMessage={noMatchesMessage}
                  noResultMessage={noResultMessage}
                  noMatches={!data?.items?.length}
                  searchQuery={input}
                />
              )}
              {showResults && (
                <>
                  <SearchCardsContainer
                    items={data?.items.slice(0, visibleItems)}
                    noSearchMadeMessage={noSearchMadeMessage}
                    label={totalMatchesLabel}
                    searchQuery={input}
                    totalMatches={data?.totalMatching}
                    loading={loading}
                    loaders={visibleItems}
                  />
                  {showMoreButton && (
                    <Box
                      width="full"
                      justifyContent="flex-end"
                      pt={7}>
                      <TextButtonIcon
                        link={{ ...searchLink, href: url }}
                        direction="right">
                        <ArrowRight />
                      </TextButtonIcon>
                    </Box>
                  )}
                </>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default MainMenuSearch;
