import ErrorAlert from "@@/components/Error/ErrorAlert";
import Pagination from "@@/components/Pagination";
import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import useUpdateEffect from "@@/hooks/useUpdateEffect";
import { LinkData } from "@@/models/linkdata";
import {
  useEffect, useState,
} from "react";
import { scroller } from "react-scroll";
import LinkModule, { LinkGroup } from "../../LinkModule";
import SearchInput from "../../SearchInput/ListingPage";
import SearchCardsContainer from "./Cards";
import SearchFilter from "./Filter";
import HelpMessage from "./HelpMessage";
import SearchPageHero from "./Hero";
import useFetchCareerData from "./useFetchSearch";

type SearchRequest = {
  language: string;
  parentpageid: number;
  page: number;
  categories: number[],
  query: string;
};

type Props = {
  header: string;
  filterLabel: string;
  placeholder: string;
  resetLabel: string;
  totalMatchesLabel: string;
  searchRequest: SearchRequest;
  errorMessage: string;
  errorActionMessage: string;
  noResultMessage: string;
  noSearchMadeMessage: string;
  noMatchesMessage: string;
  suggestionMessage: string;
  searchLinks: LinkGroup[];
  breadcrumbs: LinkData[];
};

const PARAMETERS = {
  PAGE: "p",
  CATEGORIES: "c",
  QUERY: "q",
  LANGUAGE: "l",
};

const ListingSearchContainer = ({
  header,
  filterLabel,
  placeholder,
  resetLabel,
  totalMatchesLabel,
  searchRequest,
  errorMessage,
  errorActionMessage,
  noResultMessage,
  noMatchesMessage,
  noSearchMadeMessage,
  suggestionMessage,
  searchLinks,
  breadcrumbs,
}: Props) => {
  const [selectedPage, setSelectedPage] = useState(searchRequest.page ?? 1);
  const [searchInput, setSearchInput] = useState(searchRequest.query ?? "");
  const [query, setQuery] = useState(searchRequest.query ?? "");
  const [selectedCategories, setSelectedCategories] = useState(searchRequest.categories ?? []);
  const [filterLoading, setFilterLoading] = useState(true);
  const [initalLoader, setInitialLoader] = useState(!!searchRequest.query || !!searchRequest.categories);

  const {
    data, loading, error,
  } = useFetchCareerData(
    selectedPage,
    query,
    selectedCategories,
    searchRequest,
  );

  useEffect(() => {
    const { search } = window.location;
    const params = new URLSearchParams(search);

    params.set(PARAMETERS.PAGE, selectedPage.toString());
    if (query?.length > 0) {
      params.set(PARAMETERS.QUERY, query.toString());
    } else {
      params.delete(PARAMETERS.QUERY);
    }
    if (selectedCategories?.length > 0) {
      params.set(PARAMETERS.CATEGORIES, selectedCategories.toString());
    } else {
      params.delete(PARAMETERS.CATEGORIES);
    }

    window.history.replaceState(null, null, `?${params.toString()}`);
  }, [selectedPage, selectedCategories, query]);

  useUpdateEffect(() => {
    scroller.scrollTo("listing-page-scroll", { duration: 200, smooth: true, offset: -100 });
  }, [selectedPage]);

  useUpdateEffect(() => {
    setSelectedPage(1);
    setSearchInput(query);
  }, [selectedCategories, query]);

  useEffect(() => {
    if (!loading) {
      setFilterLoading(false);
    }
  }, [loading]);

  const resetFilter = () => setSelectedCategories([]);

  const setSearchQueryWithLoading = (value) => {
    setQuery(value);
    setFilterLoading(true);
    document.getElementById("listing-page-search-input").blur();
  };

  const resetQuery = () => {
    if (!query.length) setSearchInput("");
    else {
      setQuery("");
    }
  };

  useEffect(() => {
    if (data) setInitialLoader(true);
  }, [data]);

  const resetDisabled = !selectedCategories?.length;
  const searchDisabled = searchInput === query;
  const showPagination = data && !!data.items.length && data.totalPage > 1 && !loading;
  const showHelpMessage = !!query.length && data && (data.suggestions || !data.items.length) && !filterLoading;

  return (
    <>
      <SearchPageHero
        header={header}
        breadcrumbs={breadcrumbs}
        searchInput={(
          <Box
            customStyle={{ scrollMargin: "20px" }}
            id="listing-page-scroll">
            <SearchInput
              input={searchInput}
              setInput={setSearchInput}
              searchQuery={query}
              setSearchQuery={setSearchQueryWithLoading}
              resetInput={resetQuery}
              placeholder={placeholder}
              disabled={searchDisabled}
              language={searchRequest.language}
            />
          </Box>
        )}
      />
      <Container
        pageGutterY={0} 
        mt={[6, 14, 14]}
        mb={24}>
        {!error && (
          <>
            {showHelpMessage && (
              <Box mb={14}>
                <HelpMessage
                  suggestions={data.suggestions}
                  suggestionMessage={suggestionMessage}
                  noMatchesMessage={noMatchesMessage}
                  noResultMessage={noResultMessage}
                  noMatches={!data?.items?.length}
                  setQuery={setSearchQueryWithLoading}
                  searchQuery={query}
                />
              </Box>
            )}
            <SearchFilter
              categories={data?.categoryFacets}
              selectedCategories={selectedCategories}
              setSelectedCategories={setSelectedCategories}
              filterLabel={filterLabel}
              reset={resetFilter}
              clearFilterLabel={resetLabel}
              disabled={resetDisabled}
              loading={filterLoading && initalLoader}
            />
            <SearchCardsContainer
              label={totalMatchesLabel}
              searchQuery={query}
              noSearchMadeMessage={noSearchMadeMessage}
              totalMatches={data?.totalMatching}
              items={data?.items}
              loading={loading && initalLoader}
            />
            {showPagination && (
              <Box mt={[7, 10, 10]}>
                <Pagination
                  totalPages={data.totalPage}
                  selectedValue={selectedPage}
                  setSelectedValue={setSelectedPage}
                  dark
                />
              </Box>
            )}
            {(!data?.items.length && !loading && !!searchLinks.length) && (
              <Box mt={[14, 23, 23]}>
                <LinkModule items={searchLinks} />
              </Box>
            )}
          </>
        )}
        {error && (
          <ErrorAlert
            message={errorMessage}
            action={resetQuery}
            actionText={errorActionMessage} />
        )}
      </Container>
    </>
  );
};

export default ListingSearchContainer;
