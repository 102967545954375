import SnwButtonContainer from "@@/components/Button/SnwButtonContainer";
import { SnwButtonType } from "@@/components/Button/buttonProps";
import ServiceDescriptionCard, {
  ServiceDescriptionCardProps,
} from "@@/components/Card/Service-description";
import Scroller, { ScrollerItem } from "@@/components/Scroller";
import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import Typography from "@@/elements/Typography";
import {
  ScreenSize,
  useIsBreakpointAndAbove,
} from "@@/hooks/device/use-breakpoint";
import EpiContent from "@@/integrations/optimizly/Content";
import EpiProperty from "@@/integrations/optimizly/Property";
import cn from "classnames";
import { useEffect, useState } from "react";
import { TokenValue as bps } from "../../../design-tokens/breakpoints";

import styles from "../cardContainer.module.scss";

export type ServiceDescriptionContainerProperties = {
  header?: string;
  intro?: string;
};

export type ServiceDescriptionContainerOwnProps = {
  backgroundColor: BackgroundColor;
  buttons?: SnwButtonType[];
  size: "small" | "large";
  sliderDesktop?: boolean;
  sliderMobile?: boolean;
  cards: ServiceDescriptionCardProps[];
};

export type BackgroundColor = "beige" | "white";

export type ServiceDescriptionContainerProps =
  ServiceDescriptionContainerProperties & ServiceDescriptionContainerOwnProps;

export const colorMapper = (color) => {
  switch (color) {
  case "beige":
    return "ComplementaryBeigeTint03";
  case "white":
    return "White";
  default:
    return "ComplementaryBeigeTint03";
  }
};

const ServiceDescriptionContainer = ({
  backgroundColor,
  header,
  intro,
  buttons,
  size,
  sliderDesktop,
  sliderMobile,
  cards,
}: ServiceDescriptionContainerProps) => {
  const [slider, setSlider] = useState<boolean>(false);
  const isLarge = useIsBreakpointAndAbove(ScreenSize.LARGE);

  const containerClassNames = cn({
    [styles.container_service]: size === "large",
    [styles.container_service_small]: size === "small",
    [styles.container_service__flex]: cards.length < 3,
  });

  useEffect(() => {
    const checkIfSlider = () => {
      if (
        (sliderMobile && window.innerWidth < bps.Large) ||
        (sliderDesktop && window.innerWidth >= bps.Large)
      ) {
        setSlider(true);
      } else setSlider(false);
    };
    checkIfSlider();

    if (typeof window !== "undefined") {
      window.addEventListener("resize", checkIfSlider);

      return () => {
        window.removeEventListener("resize", checkIfSlider);
      };
    }
  }, []);

  return (
    <Box
      component="section"
      backgroundColor={colorMapper(backgroundColor)}
      className={styles.block}
    >
      <Container component="div">
        <Box
          mb={[10, 12, 14]}
          width="full"
          flexDirection="column"
          alignItems="center"
        >
          {header && (
            <EpiProperty<ServiceDescriptionContainerProperties> name="header">
              <Typography
                asElement="h2"
                variant="heading1"
                textAlign="center">
                {header}
              </Typography>
            </EpiProperty>
          )}
          {intro && (
            <EpiProperty<ServiceDescriptionContainerProperties> name="intro">
              <Typography
                variant="intro"
                textAlign="center"
                mt={8}>
                {intro}
              </Typography>
            </EpiProperty>
          )}
          {buttons && buttons.length > 0 && (
            <Box
              mt={8}
              flexDirection={["column", "row", "row"]}
              gap={6}>
              <SnwButtonContainer buttons={buttons} />
            </Box>
          )}
        </Box>
        {slider && (
          <EpiContent>
            <Scroller fullWidthItems={!isLarge && size === "large"}>
              {cards.map((card, i) => (
                <ScrollerItem
                  key={i}
                  className={
                    size !== "small"
                      ? styles.service_scroller_item
                      : styles.service_scroller_item_small
                  }
                >
                  <ServiceDescriptionCard
                    header={card.header}
                    body={card.body}
                    image={card.image}
                    size={size}
                    buttons={card.buttons}
                    backgroundColor={backgroundColor}
                    slider={slider}
                  />
                </ScrollerItem>
              ))}
            </Scroller>
          </EpiContent>
        )}
        {!slider && (
          <EpiContent>
            <Box className={containerClassNames}>
              {cards?.map((card, i) => (
                <ServiceDescriptionCard
                  key={i}
                  header={card.header}
                  body={card.body}
                  image={card.image}
                  size={size}
                  buttons={card.buttons}
                  backgroundColor={backgroundColor}
                  slider={slider}
                />
              ))}
            </Box>
          </EpiContent>
        )}
      </Container>
    </Box>
  );
};
export default ServiceDescriptionContainer;
